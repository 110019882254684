import { HypertaskCollectionStatus } from "./ProjectHypertaskCollectionStatus";
import { TaskCollectionStatus } from "./ProjectTaskCollectionStatus";

export const ProjectCollectionStatus = (props: {
  projectId: string;
  taskCount: number;
  hypertaskCount: number;
}) => {
  return (
    <>
      {props.taskCount > 0 && (
        <TaskCollectionStatus projectId={props.projectId} />
      )}
      {props.hypertaskCount > 0 && (
        <HypertaskCollectionStatus projectId={props.projectId} />
      )}
    </>
  );
};
