import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import {
  Order_By,
  ProjectHypertaskCountByDeviceQuery,
  ProjectHypertaskCountQuery,
  useProjectHypertaskCountByDeviceQuery,
  useProjectHypertaskCountQuery,
} from "src/generated/asgard/graphql";
import { createTable, TextColumn } from "src/components/table";
import { HypertaskLink } from "src/resources/CollectionPlan";
import { ProgressBar } from "src/components/ProgressBar";

export const HypertaskCollectionStatus = (props: { projectId: string }) => {
  const query = useProjectHypertaskCountQuery({
    variables: {
      project_id: props.projectId,
      order_by: { hypertask: { number: Order_By.Asc } },
    },
  });
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Hypertasks" />
      <CardContent>
        <ProjectHypertaskCountTable
          data={query.data?.project_hypertask_count}
          selectable="none"
          expandedContent={(phc) => (
            <ProjectDeviceHypertaskCounts
              projectId={props.projectId}
              hypertaskId={phc.hypertask?.id ?? ""}
            />
          )}
        />
      </CardContent>
    </Card>
  );
};

const ProjectHypertaskCountTable = createTable<
  ProjectHypertaskCountQuery["project_hypertask_count"][number]
>()({
  title: "Projects",
  keys: (ptc) => ptc.hypertask?.id ?? "",
  headers: {
    hypertask: { display: "Hypertask" },
    description: { display: "Description" },
    priority: { display: "Priority" },
    required_per_device: { display: "Required Per Device" },
    progress: { display: "Progress", info: "Includes clean recordings only" },
    recording_count_clean: { display: "Clean" },
    recording_count_dirty: { display: "Dirty" },
  },
  columns: (phc) => ({
    hypertask:
      phc.hypertask?.id && phc.hypertask?.number ? (
        <HypertaskLink
          id={phc.hypertask?.id!}
          number={phc.hypertask?.number!}
        />
      ) : (
        <></>
      ),
    description: <TextColumn value={phc.hypertask?.description ?? ""} />,
    priority: <TextColumn value={phc.priority} />,
    required_per_device: <TextColumn value={phc.min_per_device} />,
    progress: (
      <ProgressBar
        hideFraction
        numerator={phc?.progress ?? 0}
        denominator={100}
      />
    ),
    recording_count_clean: (
      <TextColumn
        value={`${phc.clean_count ?? 0} / ${phc.required_total ?? 0}`}
      />
    ),
    recording_count_dirty: <TextColumn value={phc.dirty_count} />,
  }),
});

const ProjectDeviceHypertaskCounts = (props: {
  projectId: string;
  hypertaskId: string;
}) => {
  const { data } = useProjectHypertaskCountByDeviceQuery({
    variables: {
      project_id: props.projectId,
      hypertask_id: props.hypertaskId,
      order_by: [{ device: { serialno: Order_By.Asc } }],
    },
  });
  return (
    <ProjectDeviceHypertaskCountsTable
      data={data?.project_hypertask_count}
      selectable="none"
    />
  );
};

const ProjectDeviceHypertaskCountsTable = createTable<
  NonNullable<
    ProjectHypertaskCountByDeviceQuery["project_hypertask_count"]
  >[number]
>()({
  keys: (item) => item.device_id ?? "",
  title: "Category",
  headers: {
    device: { display: "Device" },
    progress: { display: "Progress" },
    num_clean_recordings: { display: "Clean" },
    num_dirty_recordings: { display: "Dirty" },
  },
  columns: (item) => ({
    device: <TextColumn value={item.device?.serialno ?? ""} />,
    progress: (
      <ProgressBar
        hideFraction
        numerator={item.clean_count ?? 0}
        denominator={item.min_per_device ?? 0}
      />
    ),
    num_clean_recordings: (
      <TextColumn
        value={`${item.clean_count ?? 0} / ${item.min_per_device ?? 0}`}
      />
    ),
    num_dirty_recordings: <TextColumn value={item.dirty_count ?? 0} />,
  }),
});
