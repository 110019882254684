import { ResponsiveHeatMap } from "@nivo/heatmap";
import { useState, useCallback, useMemo, ChangeEvent } from "react";
import {
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  makeStyles,
  Switch,
  Theme,
  Tooltip,
  createStyles,
} from "@material-ui/core";

import {
  useRecordingsCountByGroupQuery,
  RecordingsCountByGroupQuery,
} from "src/generated/asgard/graphql";

type RecordingCountItem = NonNullable<
  RecordingsCountByGroupQuery["project_recordings_count_by_group"]
>[number];

interface RecordingsDiversityChartProps {
  project_id: string;
}

interface HeatMapDataItem {
  [key: string]: string | number;
}

interface GroupByParameters {
  group_by_device: boolean;
  group_by_user: boolean;
  group_by_task: boolean;
  group_by_hypertask: boolean;
  group_by_room: boolean;
}

enum Variable {
  Device = "device",
  User = "user",
  Task = "task",
  Hypertask = "hypertask",
  Room = "room",
}

const MAX_MARGIN = 150;

// The colorSchemes export is available only in newer versions of @nivo/colors
// TODO: Update the @nivo/colors package to the latest version, import color scheme,
// and apply a transpose instead of hardcoded values..
const TRANSPOSED_NIVO_COLOR_SCHEME = [
  "#97e3d5",
  "#7CD8C8",
  "#61cdbb",
  "#5AD7B4",
  "#f1e15b",
  "#e8c1a0",
  "#f47560",
];

const axisOptions = [
  Variable.Device,
  Variable.Task,
  Variable.Hypertask,
  Variable.Room,
  Variable.User,
];

const variableToFieldMap: Record<Variable, keyof RecordingCountItem> = {
  [Variable.Device]: "device_serialno",
  [Variable.User]: "user_name",
  [Variable.Task]: "task_legacy_template_id",
  [Variable.Hypertask]: "hypertask_number",
  [Variable.Room]: "room_name",
};

const variableToGroupByKey: Record<Variable, keyof GroupByParameters> = {
  [Variable.Device]: "group_by_device",
  [Variable.User]: "group_by_user",
  [Variable.Task]: "group_by_task",
  [Variable.Hypertask]: "group_by_hypertask",
  [Variable.Room]: "group_by_room",
};

function multiTypeSort(aVal: string | number, bVal: string | number): number {
  const aStr = aVal.toString();
  const bStr = bVal.toString();

  const aNum = parseInt(aStr);
  const bNum = parseInt(bStr);

  const aIsNum = !isNaN(aNum);
  const bIsNum = !isNaN(bNum);

  if (aIsNum && bIsNum) {
    return aNum - bNum;
  }

  if (aIsNum && !bIsNum) {
    return -1;
  }
  if (!aIsNum && bIsNum) {
    return 1;
  }

  return aStr.localeCompare(bStr);
}

function transformDataForHeatMap(
  data: RecordingCountItem[],
  groupByFields: (keyof RecordingCountItem)[],
  valueField: keyof RecordingCountItem,
): {
  data: HeatMapDataItem[];
  keys: string[];
  indexBy: string;
} {
  const indexField = groupByFields[0];
  const keyField = groupByFields[1];
  const keysSet = new Set<string>();
  const dataMap: { [indexValue: string]: HeatMapDataItem } = {};

  data.forEach((item) => {
    const indexValue = item[indexField]?.toString() || "Unknown";
    const key = item[keyField]?.toString() || "Unknown";
    const rawValue = item[valueField];
    const value = typeof rawValue === "number" ? rawValue : 0;

    keysSet.add(key);

    if (!dataMap[indexValue]) {
      dataMap[indexValue] = { [indexField]: indexValue };
    }

    dataMap[indexValue][key] = value;
  });

  return {
    data: Object.values(dataMap),
    keys: Array.from(keysSet),
    indexBy: indexField as string,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchControl: {
      marginRight: theme.spacing(2),
      flexShrink: 0,
    },
    formControl: {
      minWidth: 200,
      marginRight: theme.spacing(2),
      flexShrink: 0,
    },
    propsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    dropdownContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
      flex: 1,
    },
  }),
);

export function RecordingsDiversityChart({
  project_id,
}: RecordingsDiversityChartProps) {
  const classes = useStyles();
  const [includeDirty, setIncludeDirty] = useState(false);
  const [topAxis, setXAxis] = useState<Variable>(axisOptions[4]);
  const [leftAxis, setYAxis] = useState<Variable>(axisOptions[0]);

  function getGroupByParameters(
    topAxis: Variable,
    leftAxis: Variable,
  ): GroupByParameters {
    return {
      group_by_device: false,
      group_by_user: false,
      group_by_task: false,
      group_by_hypertask: false,
      group_by_room: false,
      [variableToGroupByKey[topAxis]]: true,
      [variableToGroupByKey[leftAxis]]: true,
    };
  }

  const { loading, error, data } = useRecordingsCountByGroupQuery({
    variables: {
      project_id: project_id,
      include_dirty: includeDirty,
      ...getGroupByParameters(topAxis, leftAxis),
    },
  });

  const groupByFields = useMemo(() => {
    return [variableToFieldMap[leftAxis], variableToFieldMap[topAxis]];
  }, [topAxis, leftAxis]);
  const valueField: keyof RecordingCountItem = "recording_count";
  const { sortedHeatMapData, sortedKeys, indexBy } = useMemo(() => {
    const recordingCount = data?.project_recordings_count_by_group || [];
    const {
      data: rawHeatMapData,
      keys: rawKeys,
      indexBy,
    } = transformDataForHeatMap(recordingCount, groupByFields, valueField);

    const sortedHeatMapData = [...rawHeatMapData].sort((a, b) =>
      multiTypeSort(a[indexBy], b[indexBy]),
    );

    const sortedKeys = [...rawKeys].sort((a, b) => multiTypeSort(a, b));
    return { sortedHeatMapData, sortedKeys, indexBy };
  }, [data?.project_recordings_count_by_group, groupByFields]);
  const handleXAxisChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      setXAxis(event.target.value as Variable);
    },
    [],
  );

  const handleYAxisChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      setYAxis(event.target.value as Variable);
    },
    [],
  );

  const longestKeyLength = useMemo(() => {
    return sortedKeys.reduce((max, k) => Math.max(max, k.length), 0);
  }, [sortedKeys]);

  const longestLeftAxisLabelLength = useMemo(() => {
    const lengths = sortedHeatMapData.map((d) => {
      const label = d[indexBy]?.toString() || "";
      return label.length;
    });
    return Math.max(0, ...lengths);
  }, [sortedHeatMapData, indexBy]);

  const dynamicLeftMargin = useMemo(() => {
    const calculatedMargin = 30 + longestLeftAxisLabelLength * 5;
    return Math.min(calculatedMargin, MAX_MARGIN);
  }, [longestLeftAxisLabelLength]);

  const dynamicTopMargin = useMemo(() => {
    const calculatedMargin = 10 + longestKeyLength * 5;
    return Math.min(calculatedMargin, MAX_MARGIN);
  }, [longestKeyLength]);

  const margin = useMemo(() => {
    return {
      top: dynamicTopMargin,
      right: 20,
      bottom: 10,
      left: dynamicLeftMargin,
    };
  }, [dynamicTopMargin, dynamicLeftMargin]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      {/* Dropdown menus for variable selection */}
      <div className={classes.propsContainer}>
        {/* Switch to include dirty recordings */}
        <Tooltip title="Include dirty recordings">
          <FormControlLabel
            className={classes.switchControl}
            control={
              <Switch
                checked={includeDirty}
                onChange={(e) => {
                  setIncludeDirty(e.target.checked);
                }}
              />
            }
            label="Include dirty"
          />
        </Tooltip>
        <div className={classes.dropdownContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel id="left-axis-label">Left-Axis</InputLabel>
            <Select
              labelId="left-axis-label"
              value={leftAxis}
              onChange={handleYAxisChange}
            >
              {axisOptions.map((axisOption) => (
                <MenuItem
                  key={axisOption}
                  value={axisOption}
                  disabled={axisOption === leftAxis}
                >
                  {axisOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="top-axis-label">Top-Axis</InputLabel>
            <Select
              labelId="top-axis-label"
              value={topAxis}
              onChange={handleXAxisChange}
            >
              {axisOptions.map((axisOption) => (
                <MenuItem
                  key={axisOption}
                  value={axisOption}
                  disabled={axisOption === topAxis}
                >
                  {axisOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Heatmap visualization */}
      <div style={{ height: 500 }}>
        <ResponsiveHeatMap
          data={sortedHeatMapData}
          keys={sortedKeys}
          indexBy={indexBy}
          padding={0.3}
          margin={margin}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
          }}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          colors={TRANSPOSED_NIVO_COLOR_SCHEME}
          colorBy="index"
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={false}
          forceSquare={false}
          hoverTarget="cell"
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
}
