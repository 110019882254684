import React, { useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  Divider,
  Grid,
  IconButton,
  TextField as MUITextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Delete,
  DragIndicator,
  Edit,
} from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import { FormContainer, FormHeader, TextField } from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ClosableDrawer,
  InfoDisplay,
  InfoTooltip,
  ShowResourceView,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Bringup_Checklists as Checklist,
  Bringup_Checklists_Bool_Exp as Checklists_Bool_Exp,
  useAllChecklistsQuery,
  useChecklistQuery,
  useCreateChecklistMutation,
  useUpdateChecklistMutation,
  Order_By,
  Bringup_Items_Insert_Input,
} from "src/generated/asgard/graphql";
import { FieldArray, useField } from "formik";
import { createTriage } from "src/components/Triage";
import { MarkdownEditor } from "src/components/MarkdownEditor";
import {
  BringupChecklistRow,
  BringupChecklistTable,
} from "./BringupChecklistTable";
import { Autocomplete } from "@material-ui/lab";
import { CardHeader } from "@material-ui/core";
import Markdown from "react-markdown";

// Config table columns from Checklist fields
export const ChecklistTable = createTable<Checklist>()({
  keys: (checklist) => checklist.id ?? "",
  title: "Checklist",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    deliverables: { display: "Deliverables" },
  },
  columns: (checklist) => ({
    id: <EllipsisColumn value={checklist.id} />,
    name: <TextColumn value={checklist.name} />,
    description: <TextColumn value={checklist.description ?? ""} />,
    deliverables: (
      <ChipColumn
        chips={checklist.checklist_deliverables?.map(({ deliverable }) => ({
          label: deliverable?.name ?? "",
        }))}
      />
    ),
  }),
});

// Define a new table component for Checklists
type AllChecklistsTableProps = TableOptionals<typeof ChecklistTable> & {
  where?: Checklists_Bool_Exp[];
};

export const AllChecklistsTable = (props: AllChecklistsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Checklists_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
    searchFilters.push({ description: { _ilike: term } });
  }
  const { data } = useAllChecklistsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      // Add other query variables.
      order_by: [{ name: Order_By.Asc }],
    },
  });

  return (
    <ChecklistTable
      {...props}
      {...pageController}
      total={data?.checklists_aggregate?.aggregate?.count}
      data={data?.checklists}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Checklist resource page
type ChecklistIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ChecklistTable>;

export const ChecklistIndex = (props: ChecklistIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Checklists</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Checklist
        </Button>
      </TableViewHeader>
      <Card>
        <AllChecklistsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Checklists
const checklistItemSchema = Yup.object({
  id: Yup.string(), // Optional, for when adding new items
  key: Yup.string().required(),
  hasEndorsements: Yup.boolean().required(),
  columns: Yup.object({
    position: Yup.number().required(),
    name: Yup.string().required(),
    description: Yup.string(),
    objective: Yup.string(),
    personnel: Yup.string(),
  }).required(),
  dependsOn: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).nullable(),
  milestone: Yup.object({
    name: Yup.string().required(),
    description: Yup.string(),
  }).nullable(),
})
  .required()
  // Use `useField` for one of the "items" to get error text for an invalid
  // dependency.
  .test(
    "dependency-missing",
    "Cannot depend on item not in list",
    function (value) {
      const items = this.parent;
      const itemIds =
        items?.map((item: { id?: string }) => item.id ?? null) ?? [];
      if (!!value.dependsOn?.id && !itemIds.includes(value.dependsOn.id)) {
        return false;
      }
      return true;
    },
  )
  .test(
    "dependency-order",
    "Cannot depend on item from later in list",
    function (value) {
      const items = this.parent;
      const itemIds =
        items?.map((item: { id?: string }) => item.id ?? null) ?? [];
      if (
        !!value.dependsOn?.id &&
        itemIds.indexOf(value.dependsOn.id) > value.columns.position
      ) {
        return false;
      }
      return true;
    },
  );

const checklistSchema = Yup.object({
  name: Yup.string().required("required"),
  description: Yup.string(),
  items: Yup.array(checklistItemSchema),
}).required();

type ItemSchema = Yup.InferType<typeof checklistItemSchema>;

const TriageChecklistItems = createTriage<{ key: string }, ItemSchema>();

export type ChecklistFormProps = {
  action: FormAction;
  checklist?: { id: string };
  onSuccess?: (id: string) => void;
};

export const ChecklistForm = (props: ChecklistFormProps) => {
  const [createChecklist] = useCreateChecklistMutation();
  const [updateChecklist] = useUpdateChecklistMutation();

  const allChecklists = useAllChecklistsQuery({
    variables: {
      where: { id: { _nin: props.checklist?.id ? [props.checklist.id] : [] } },
    },
  });

  const currentChecklist = useChecklistQuery({
    variables: { id: props.checklist?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.checklist,
  });

  return (
    <ResourceForm
      skipValidateOnChange
      action={props.action}
      schema={checklistSchema}
      resourceToUpdate={currentChecklist.data?.checklist}
      transform={(checklist) => ({
        name: checklist.name,
        description: checklist.description ?? "",
        items: checklist.items?.map((item) => ({
          id: item.id,
          key: `${item.position}`,
          hasEndorsements:
            (item.endorsements_aggregate?.aggregate?.count ?? 0) > 0,
          columns: {
            position: item.position,
            name: item.name,
            description: item.description ?? "",
            objective: item.objective ?? "",
            personnel: item.personnel ?? "",
          },
          dependsOn: !!item.depends_on_item
            ? {
                id: item.depends_on_item.id ?? "",
                name: item.depends_on_item.name ?? "",
              }
            : null,
          milestone: item.milestone?.name
            ? {
                name: item.milestone.name,
                description: item.milestone.description ?? "",
              }
            : null,
        })),
      })}
      initialValues={{
        name: "",
        description: "",
        items: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of allChecklists.data?.checklists ?? []) {
          if (existing.name === values.name) {
            errors.name = `Checklist ${values.name} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        if (!!props.checklist?.id) {
          const { items, ...columns } = values;
          // Find out which items were deleted.
          const currentItemIds: string[] =
            currentChecklist.data?.checklist?.items.map((item) => item.id) ??
            [];
          const remainingItemIds: string[] = (items ?? [])
            .filter(({ id }) => !!id)
            .map(({ id }) => id!);
          const itemIdsToDelete = currentItemIds.filter(
            (id) => !remainingItemIds.includes(id),
          );
          const milestonesToUpdate = (values.items ?? [])
            .filter((item) => !!item.id && !!item.milestone)
            .map((item) => ({
              item_id: item.id,
              name: item.milestone!.name,
              description: item.milestone?.description ?? null,
            }));
          const itemIdsWithoutMilestones = (values.items ?? [])
            .filter((item) => !!item.id && !item.milestone)
            .map((item) => item.id!);
          const result = await (async () => {
            return await updateChecklist({
              variables: {
                id: props.checklist!.id,
                columns: { ...columns },
                items: (items ?? []).map((i) => {
                  const { key, dependsOn, milestone, ...rest } = i;
                  const vals: Bringup_Items_Insert_Input = {
                    checklist_id: props.checklist!.id,
                    ...rest.columns,
                    depends_on: dependsOn?.id ?? null,
                  };
                  if (!!i.id) {
                    // The id is necessary to support upsert.
                    vals.id = i.id;
                  } else if (milestone?.name) {
                    // Can insert milestone directly, but only if the item
                    // doesn't already exist.
                    vals.milestone = { data: milestone };
                  }
                  return vals;
                }),
                item_ids_to_delete: itemIdsToDelete,
                item_ids_without_milestones: itemIdsWithoutMilestones,
                milestones: milestonesToUpdate,
              },
            });
          })();
          props.onSuccess && props.onSuccess(result.data?.checklist?.id ?? "");
        }
      }}
      onInsert={async (values) => {
        const { items, ...rest } = values;
        const result = await (async () => {
          return await createChecklist({
            variables: {
              input: {
                ...rest,
                items: {
                  data: (items ?? []).map((i) => {
                    const { columns, milestone } = i;
                    const vals: Bringup_Items_Insert_Input = { ...columns };
                    if (milestone) vals.milestone = { data: milestone };
                    return vals;
                  }),
                },
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.checklist?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.checklist ? "Update Checklist" : "Create New Checklist"}
          </FormHeader>
          <FormContainer>
            <Grid item>
              <Typography color="error">
                Warning! Edits made to a checklist are propagated to all
                projects that use it! Therefore, you should generally <i>not</i>{" "}
                change the conditions for completing a particular item.
                <br />
                <br />
                For example, if an item dictates making 10 recordings, and is
                marked as complete for a particular project, changing the item
                so that it dictates making 100 recordings will not clear the
                endorsement, and it will look like 100 recordings were made for
                this project instead of the actual 10.
              </Typography>
            </Grid>
            <TextField bp={{ xs: 12, sm: 4 }} name={path.name._} label="Name" />
            <TextField
              bp={{ xs: 12, sm: 10 }}
              multiline
              name={path.description?._ ?? "description"}
              label="Description"
            />
          </FormContainer>
          <FormContainer>
            <FieldArray
              name={path.items?._ ?? "items"}
              render={(helper) => (
                <>
                  <Grid item xs={12}>
                    <TriageChecklistItems
                      id="triage-checklist-items"
                      groups={formik.values.items ?? []}
                      groupDirection="vertical"
                      renderGroup={({ index, group }) => (
                        <ChecklistItemForm
                          item={group}
                          path={path.items![index]!}
                          possibleDependencies={
                            formik.values.items
                              ?.filter((item, i) => !!item.id && index > i)
                              .map((item) => ({
                                id: item.id!,
                                name: item.columns.name,
                              })) ?? []
                          }
                          onDependencyChange={(dep) => {
                            formik.setFieldValue(
                              path.items![index].dependsOn._,
                              dep,
                              // Validate on change, to catch/clear any issues with
                              // dependencies.
                              true,
                            );
                          }}
                          onMilestoneChange={(m) =>
                            helper.replace(index, { ...group, milestone: m })
                          }
                          onDelete={() => {
                            formik.setFieldValue(
                              path.items!._,
                              (formik.values.items ?? [])
                                .filter((_, i) => i !== index)
                                .map((item, i) => ({
                                  ...item,
                                  key: `${i}`,
                                  columns: {
                                    ...item.columns,
                                    position: i,
                                  },
                                })),
                            );
                          }}
                        />
                      )}
                      onChange={(groups) => {
                        // Re-number the positions each time the groups are
                        // rearranged.
                        formik.setFieldValue(
                          "items",
                          groups.map((g, i) => ({
                            ...g,
                            key: `${i}`,
                            columns: {
                              ...g.columns,
                              position: i,
                            },
                          })),
                          // Validate on change, to catch/clear any issues with
                          // dependencies.
                          true,
                        );
                      }}
                      /**
                       * Note, the triage component is typically used for
                       * managing lists within lists ("items" within "groups").
                       * We're using it here because it has a nice drag-and-drop
                       * interface, but we are only managing a one-dimensional
                       * list of "checklist items".
                       * This is an unfortunate mixup of terminology, as the
                       * triage component uses the term "items" to refer to the
                       * second-order dimension (which we are no using).
                       */
                      itemDirection="horizontal"
                      getGroupItems={() => []}
                      setGroupItems={() => {}}
                      renderItem={() => <></>}
                      renderItemActions={() => <></>}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      onClick={() => {
                        const pos = formik.values.items?.length ?? 0;
                        helper.push({
                          key: `${pos}`,
                          hasDependents: false,
                          hasEndorsements: false,
                          columns: {
                            position: pos,
                            name: "",
                            description: "",
                            objective: "",
                            personnel: "",
                          },
                          dependsOn: null,
                          milestone: null,
                        });
                      }}
                    >
                      Add item
                    </Button>
                  </Grid>
                </>
              )}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

type ChecklistItemFormProps = {
  item: ItemSchema;
  possibleDependencies: { id: string; name: string }[];
  onDependencyChange: (dep: { id: string; name: string } | null) => void;
  onMilestoneChange: (m: { name: string; description: string } | null) => void;
  path: any;
  onDelete: () => void;
};

const ChecklistItemForm = (props: ChecklistItemFormProps) => {
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  // A "test" is added to the "item" schema for invalid dependencies. Use any
  // error text in the dependency selector, below.
  const [, meta] = useField(props.path._);

  return (
    <>
      <Card style={{ marginTop: 8 }}>
        <CardHeader
          avatar={<DragIndicator />}
          title={
            <Grid container>
              <TextField
                bp={{ xs: 12 }}
                name={props.path.columns.name._}
                variant="standard"
                label="name"
              />
            </Grid>
          }
          subheader={
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Autocomplete
                  value={props.item.dependsOn}
                  options={props.possibleDependencies}
                  getOptionLabel={(item) => item.name}
                  getOptionSelected={(o, v) => o.id === v.id}
                  onChange={(_, nv) => props.onDependencyChange(nv)}
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={!!meta.error}
                      helperText={meta.error}
                      label="Depends on"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <InfoTooltip
                  size="large"
                  content={
                    <>
                      Note, new items will not be visible in this list until the
                      checklist is updated.
                    </>
                  }
                />
              </Grid>
            </Grid>
          }
          action={
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => setDetailsDrawerOpen(true)}
              >
                <Edit />
              </IconButton>
              <Tooltip
                title={
                  props.item.hasEndorsements
                    ? "this item cannot be deleted, as it is currently marked complete for one or more projects"
                    : ""
                }
              >
                <span>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={props.onDelete}
                    disabled={props.item.hasEndorsements}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          }
        />
        {props.item.milestone && (
          <CardActions>
            <CardActionArea>
              <InfoDisplay
                items={[
                  { label: "Milestone", value: props.item.milestone.name },
                ]}
              />
            </CardActionArea>
          </CardActions>
        )}
      </Card>
      <ClosableDrawer show={detailsDrawerOpen} setShow={setDetailsDrawerOpen}>
        <FormContainer>
          <Grid item xs={12}>
            <Typography variant="h6">{props.item.columns.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <MarkdownEditorField
            name={props.path.columns.objective._}
            label="objective"
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <MarkdownEditorField
            name={props.path.columns.personnel._}
            label="personnel"
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <MarkdownEditorField
            name={props.path.columns.description._}
            label="description"
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {props.item.milestone ? (
            <>
              <Grid item>
                <Typography variant="body1">milestone</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => props.onMilestoneChange(null)}
                >
                  <Delete />
                </IconButton>
              </Grid>
              <TextField
                bp={{ xs: 12 }}
                name={props.path.milestone.name._}
                label="name"
              />
              <TextField
                multiline
                bp={{ xs: 12 }}
                name={props.path.milestone.description._}
                label="description"
              />
            </>
          ) : (
            <Button
              onClick={() =>
                props.onMilestoneChange({ name: "", description: "" })
              }
            >
              Add milestone
            </Button>
          )}
        </FormContainer>
      </ClosableDrawer>
    </>
  );
};

type MarkdownEditorFieldProps = {
  name: string;
  label: string;
};
const MarkdownEditorField = (props: MarkdownEditorFieldProps) => {
  const [field, , helpers] = useField(props);
  const [editing, setEditing] = useState(false);
  return (
    <Grid item container spacing={2}>
      <Grid item>
        <Typography variant="body1">{props.label}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          disabled={editing}
          onClick={() => setEditing(true)}
        >
          <Edit />
        </IconButton>
      </Grid>
      {editing ? (
        <Grid item xs={12}>
          <MarkdownEditor
            data={field.value}
            onSave={(md) => {
              helpers.setValue(md);
              setEditing(false);
            }}
            onClose={() => setEditing(false)}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Markdown>{field.value}</Markdown>
        </Grid>
      )}
    </Grid>
  );
};

// Create a detailed 'show' page.
type ChecklistShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Checklist>) => void;
};

const ChecklistShow = (props: ChecklistShowProps) => {
  const checklistNav = useChecklistNav();

  const checklistQuery = useChecklistQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const checklist = checklistQuery.data?.checklist;
  if (!checklist) return null;

  return (
    <ShowResourceView
      title={checklist.name}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="checklists" onClick={() => checklistNav.list()} />
          <Breadcrumb label={checklist.id ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(checklist)}
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "items",
            label: "Items",
            content: (
              <BringupChecklistTable>
                {checklist.items.map((item) => (
                  <BringupChecklistRow
                    key={item.id}
                    item={item}
                    onChange={() => null}
                  />
                ))}
              </BringupChecklistTable>
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "checklists";
export const useChecklistNav = () => useResourceNav(path);
export const ChecklistResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <ChecklistIndex
        onShowAction={(item) => item.id && nav.show(item.id)}
        onEditAction={(item) => item.id && nav.edit(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <ChecklistShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    edit={(nav, id) => (
      <ChecklistForm
        action="update"
        checklist={{ id: id ?? "" }}
        onSuccess={(id) => nav.show(id)}
      />
    )}
    create={(nav) => (
      <ChecklistForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
  />
);
